/* @flow */

import * as React from 'react'
import { ProductTemplate } from '../templates'

type Props = {
  location: {
    href: string,
    state: Object,
  },
}

export default function Form(props: Props) {
  const { location } = props
  return (
    <ProductTemplate
      url={location.href}
      title="Fidélisez vos clients et boostez votre chiffre d'affaires"
      media="illu_product-fidelity.png"
      name="Fidélité"
      label="Fidélité"
      description="Augmentez rapidement le panier moyen de vos clients et votre trafic en boutique avec un programme de fidélité sur-mesure paramétrable depuis votre logiciel de caisse."
      body={[
        {
          name: 'features',
          items: [
            {
              media: 'icon_product-fidelity_customprogram.png',
              title: 'Un programme de fidélité flexible',
              content: 'Définissez vos offres et le moment où elles seront déclenchées',
            },
            {
              media: 'icon_product-fidelity_daily.png',
              title: 'Une expérience client réinventée',
              content:
                'Échangez régulièrement avec vos clients grâce au programme de fidélité et faites-leur plaisir',
            },
            {
              media: 'icon_product-fidelity_monitoring.png',
              title: 'Un suivi complet',
              content:
                "Suivez vos clients membres et analysez l'impact du programme de fidélité sur vos activités ",
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'crater',
              media: 'screenshot_fidelity_customprogram.png',
              alt: 'programme personnalisable',
              label: 'Un programme personnalisable',
              title: "Créez un programme de fidélité à l'image de votre commerce",
              content:
                "Choisissez votre système de gain de points : selon la valeur du panier pour les inciter à acheter plus ou selon le nombre de passages pour les faire revenir souvent. Récompensez-les aussi à leur inscription au programme de fidélité ou pour leur anniversaire ! Déterminez vos offres parmi une multitude de possibilités : produits offerts, remises, bons d'achat…",
            },
            {
              decoration: 'mexicali',
              media: 'screenshot_fidelity_daily.png',
              alt: 'nouvelle expérience client',
              label: 'Une expérience client réinventée',
              title: 'Échangez avec vos clients et faites-leur plaisir pour les voir revenir !',
              content:
                'Envoyez-leur des mails automatiques à leur inscription, après chaque achat pour récapituler leurs achats et leurs offres ou encore quand leurs points vont expirer. Dès le premier mois, vos clients reviennent plus souvent pour profiter de leurs avantages !',
            },
          ],
        },
        {
          name: 'informationInsert',
          items: [
            {
              media: 'oncashregister',
              title: 'Carte de fidélité',
              content: `Pour faire profiter vos clients du programme de fidélité lors de leurs passage en caisse, rien de plus simple ! Ceux-ci n'ont qu'à vous donner leur nom car la carte de fidélité est 100% dématérialisée. Une chance pour vos clients qui ne risquent pas d'oublier leur carte.`,
            },
            {
              media: 'free',
              title: "15 jours d'essai gratuit",
              content:
                "L'abonnement au module Fidélité est complémentaire au logiciel de caisse. Vous avez 15 jours pour l'essayer gratuitement ! C'est ensuite à l'usage que vous payerez l'abonnement.",
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'landing',
              media: 'screenshot_fidelity_monitoring.png',
              alt: 'suivi de la fidélité',
              label: 'Un suivi complet',
              title: 'Suivez vos membres, mesurez vos performances et avancez !',
              content:
                "Depuis l'écran d'encaissement et le répertoire clients, vous savez tout de vos clients membres : leurs produits préférés, leurs points cumulés et dépensés,  ainsi que leurs récompenses utilisées et disponibles. Mesurez l'impact du programme de fidélité sur la progression de vos ventes : le panier moyen, la fréquence des visites ainsi que le chiffre d'affaires généré.",
            },
          ],
        },
      ]}
    />
  )
}
